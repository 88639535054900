import React from 'react';
import ReactDOM from  'react-dom';

export default function Portal(WrappedComponent: any) {
    return class extends React.Component<any, any>{
        node: any;
        constructor(props: any){
            super(props);
            //直接插入dom节点到body下
            if(!this.node){
                this.node = document.createElement("div");
                this.node.setAttribute('id', props.id || '');
                const root = document.getElementById('root');
                const body = document.getElementsByTagName('body')[0];
                body.insertBefore(this.node, root && root.nextSibling);
            }
        }
        //组件即将卸载时候删除dom节点
        componentWillUnmount(){
            this.node && this.node.remove();

        }
        //渲染内容
        renderContent(){
            return (
                <div>
                    <div className="portal-bgc"/>
                    {/*给WrappedComponent传递props*/}
                    <WrappedComponent {...this.props}/>
                </div>
            )
        }
        render(){
            return (
                this.node && ReactDOM.createPortal(this.renderContent(), this.node)
            )
        }
    }
}