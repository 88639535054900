import React, { Component } from 'react'
import ReactDom from 'react-dom';
import ToastContainer from './ToastContainer'
import './index.scss'
const toastContainerDiv = document.createElement('div');
document.body.appendChild(toastContainerDiv);

// 这里返回的是 ToastContainer 组件引用
const getToastContainerRef = () => {
  // 将 <ToastContainer /> React 组件，渲染到 toastContainerDiv 中，并且返回了 <ToastContainer /> 的引用
  return ReactDom.render(<ToastContainer />, toastContainerDiv);
}

// 这里是 <ToastContainer /> 的引用
let toastContainer = getToastContainerRef();


export default {
  info: (text, duration, isShowMask) => (toastContainer.pushToast({ type: 'info', text, duration, isShowMask })),
};
