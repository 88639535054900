import React from 'react';
import bxlutils from 'bxl-utils';
import './package-detail.scss';

function PackageDetail(props: any) {
    const { lastProgress, packageNo, shipperAddress, consigneeAddress } = props || {};
    const { location, activity, timestamp } = lastProgress || {};

    return (
        <div className='m-package-detail'>
            <div className='package-info-wrap'>
                <div className='package-no'>运单号：{packageNo || '-'}</div>
                {shipperAddress && consigneeAddress &&
                    <div className='package-location'>
                        {shipperAddress}
                        <img src={require('@images/arrow.png')} />
                        {consigneeAddress}
                    </div>
                }
            </div>
            <div className='state-wrap'>
                {location && <div className='state'>{location} {activity}</div>}
                <div className='time'>{bxlutils.formatter.format(timestamp, 'YYYY年MM月DD日 HH:mm')}</div>
            </div>
        </div>
    )
}

export default PackageDetail;