import React, { useEffect, useState } from 'react';
import PackageDetail from '@modules/PackageDetail';
import Progress from '@modules/Progress';
import Segmented from '@modules/Segmented';
import { getLogisticsTraces } from '@services/api';
import bxlutils from 'bxl-utils';
import { LOG_HISTORY } from '@lib/constants';
import LocalStorage from '@lib/localStorage';
import Portal from '@components/Portal';
import Toast from '@components/Toast';
import Loading from '@components/Loading';
import Foot2 from '@images/footer_1.png';
import './App.scss';
const _dx = (window as any)['_dx'];

const App: React.FC = () => {
    const p = bxlutils.uri.parseUrl(window.location.href)?.query;
    const id = p.id;
    const [input, setInput] = useState(id);
    const [traceId, setTraceId] = useState('');
    const [traces, setTraces]: any = useState();
    const [history, setHistory] = useState(JSON.parse(LocalStorage.get(LOG_HISTORY) || '[]'));
    const { consigneeAddress, shipperAddress, progresses } = traces || {};
    const [loading, setLoading] = useState(false);
    const [firstPageShow, setFirstPageShow] = useState(true);
    let myCaptcha: any = null;

    function handleSearch() {
        if (!input || loading) return;
        myCaptcha = _dx.Captcha(document.getElementById('verification'), {
            appId: '2bda5bbd993d9363d22707dc50ea6ef6',
            style: 'popup',
            success: function (token: string) {
                myCaptcha.hide();
                const history = JSON.parse(LocalStorage.get(LOG_HISTORY) || '[]');
                const isExisting = history?.findIndex(item => item === input) >= 0;
                if(!isExisting) {
                    history?.unshift(input);
                    const newHistory = history.slice(0, 3);
                    LocalStorage.set(LOG_HISTORY, JSON.stringify(newHistory));
                    setHistory(newHistory);
                }
                setTraceId(input);
                setLoading(true);
                setTraces(null);
                getLogisticsTraces(input)
                    .then((res) => {
                        setTraces(res);
                        setLoading(false);
                    }).catch((err) => {
                        Toast.info(err?.message, 2);
                        setLoading(false);
                        setFirstPageShow(false);
                    })
            }
        });
        myCaptcha.show();
    }

    function handleInputChange(e) {
        setInput(e.target.value);
    }

    function handleHistoryDelete(e, value, index) {
        e.stopPropagation();
        const history = JSON.parse(LocalStorage.get(LOG_HISTORY) || '[]');
        if (history[index] === value) {
            history.splice(index, 1);
        }
        LocalStorage.set(LOG_HISTORY, JSON.stringify(history));
        setHistory(history);
    }

    function handleHistoryClick(value) {
        setInput(value);
    }

    function renderContent() {
        if(progresses && progresses.length) {
            return (
                <div className='card'>
                    <PackageDetail lastProgress={progresses?.[0] || []} packageNo={traceId} consigneeAddress={consigneeAddress} shipperAddress={shipperAddress} />
                    <Progress phase={progresses?.[0]?.phase} />
                    <Segmented progress={progresses} />
                </div>
            )
        } else if(firstPageShow) {
            return (
                <div className='card empty'>
                    <div className='text'>请在上方输入运单号查询</div>
                </div>
            )
        } else {
            return (
                <div className='card empty'>
                    <img src={require('@images/empty.png')} />
                </div>
            )
        }
    }

    return (
        <div className="p-logistics">
            <div className='header'>
                <div className='logo-wrap'>
                    <img className='logo' src={require('@images/logo.png')} />
                </div>
            </div>
            <div className='header-placeholder'></div>
            <div className='content'>
                <div className='search'>
                    <input value={input} className='search-input' placeholder='请输入11位运单号' onChange={handleInputChange} />
                    <div className='search-button' onClick={handleSearch}>查询</div>
                    <img className='close-icon' src={require('@images/close.png')} onClick={() => setInput('')} />
                </div>
                <div className='history'>
                    {history?.map((item, index) => {
                        return (
                            <div className='item' key={index} onClick={() => handleHistoryClick(item)}>
                                {item}
                                <img className='close-icon' src={require('@images/close.png')} onClick={(e) => handleHistoryDelete(e, item, index)} />
                            </div>
                        )
                    })}
                </div>
                {renderContent()}
            </div>
            <div className='footer'>
                Copyright © 2015-2022, 上海别样秀数据科技有限公司, 上海市浦东新区张江高科科苑路399号10号楼12层, 021-61514616, All rights reserved. 
                <a target='_blank' rel='nofollow' href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备18004034号-3</a>
                <a target='_blank' href="https://baleen-cdn-g.bieyangapp.com/by/info/icpediwatermark.pdf">增值电信业务经营许可证：沪B2-20190462</a>
                <a target='_blank' rel='nofollow' href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020190319171346000004469222-SAIC_SHOW_310000-4028e4cb69e32371016a44ea858c3934228&signData=MEYCIQDHbTLsJBMSvGqSpEhaa8UbCIMHuGAKsE6890TxMhoI9gIhANnjtCP9obDgUPyROL8e4lYUTfTIT1cDh5NLBl7k2MOl">
                <img src={Foot2} alt="verify-icon" />
                </a>
            </div>
            <Verification />
            {loading && <Loading />}
        </div>
    );
}

function VerificationModules() {
    return (
        <div id='verification'></div>
    )
}

const Verification = Portal(VerificationModules);

export default App;