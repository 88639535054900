import React, { Component } from 'react'

class ToastItem extends Component {
  timer = null
  componentDidMount() {
    const { id, duration, onClose, isShowMask } = this.props;
    this.timer = setTimeout(() => {
      if (onClose) {
        onClose(id, isShowMask);
      }
    }, duration * 1000)
  }
  componentWillUnmount() {
    clearTimeout(this.timer)
  }
  render() {
    const { text } = this.props;
    return (
      <div className="toast-item">
        {text}
      </div>
    );
  }
}

export default ToastItem