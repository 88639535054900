import React from 'react';
import { PHASE_NAME, PHASE_ORDER } from '@lib/constants';
import './progress.scss';

function Progress(props: any) {
    const { phase } = props;

    return (
        <div className='m-progress'>
            {PHASE_ORDER.map((item, index) => {
                const isLast = index === PHASE_ORDER.length - 1;
                const activeIndex = PHASE_ORDER.indexOf(phase);
                const itemName = index < activeIndex ? 'available' :
                                   index > activeIndex ? 'unavailable' : 'active';
                return (
                    <div className={`progress-item ${itemName}`} key={index}>
                        <div className='icon-wrap'>
                            <div className='circle'></div>
                            <div className={`line ${isLast ? 'invisible' : ''}`}></div>
                        </div>
                        <div className='text'>{PHASE_NAME[item]}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default Progress;