export const PHASE = {
    'WAREHOUSE': 'WAREHOUSE',
    'AIRLINE': 'AIRLINE',
    'CUSTOM': 'CUSTOM',
    'LAST_MILE_LOGISTIC': 'LAST_MILE_LOGISTIC',
    'COMPLETED': 'COMPLETED'
}

export const PHASE_NAME = {
    [PHASE.WAREHOUSE]: '仓库',
    [PHASE.AIRLINE]: '航空',
    [PHASE.CUSTOM]: '清关',
    [PHASE.LAST_MILE_LOGISTIC]: '国内派送',
    [PHASE.COMPLETED]: '用户签收'
}

export const PHASE_ORDER = [
    PHASE.WAREHOUSE,
    PHASE.AIRLINE,
    PHASE.CUSTOM,
    PHASE.LAST_MILE_LOGISTIC,
    PHASE.COMPLETED
];

export const LOG_HISTORY = 'LOG_HISTORY';