import React from 'react';
import bxlutils from 'bxl-utils';
import './segmented.scss';

function Segmented(props: any) {
    const { progress } = props;

    return (
        <div className='m-segmented'>
            {progress?.map((item, index) => {
                const { location, activity, iconUrl, timestamp } = item || {};
                const isFirst = index === 0;
                const isLast = index === progress?.length - 1;
                return (
                    <div className='segment-item' key={index}>
                        <div className='timeline'>
                            <div className={`line line-top ${isFirst ? 'invisible' : ''}`}></div>
                            {iconUrl ?
                                <img className='icon' src={iconUrl} /> : <div className='circle'></div>
                            }
                            <div className={`line line-bottom ${isLast ? 'invisible' : ''}`}></div>
                        </div>
                        <div className='detail'>
                            <div className='main-info'>{location} {activity}</div>
                            <div className='time'>{bxlutils.formatter.format(timestamp, 'MM-DD HH:mm')}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Segmented;