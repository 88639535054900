import axios from 'axios';
import Toast from '../components/Toast'

const DEVICE_ID = 'BXL_META_device_id';
let basePath
if (process.env.REACT_APP_BASE_ENV === 'dev') {
  basePath = 'https://laas-dev.bybieyang.com'
} else {
  basePath = 'https://www.santasmartnetwork.com/import'
}

const http = axios.create({
  baseURL: basePath,
  headers: {}
})

const errorMessage = (error) => {
  let message = null;
  if (error.response) {
    error = error.response.data
  }
  if (error.messages && error.messages.length > 0) {
    message = error.messages[0];
  } else if (error.errors && error.errors.length > 0) {
    message = error.errors[0];
  } else if (error.message) {
    message = error.message;
  }

  return message;
}
http.interceptors.request.use(function (config) {
  config.headers = {
    ...config.header,
    // "X-Session-Key": localStorage.getItem('BXL_USER_KEY') || '',
    // "X-Session-User": localStorage.getItem('BXL_USER_ID') || '',
    // "X-Session-DeviceID": localStorage.getItem(DEVICE_ID) || "",
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error?.response?.data);
});

export default http